import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { ReactCompareSlider } from "react-compare-slider";
import { Autoplay, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import IntroMp4 from "../images/intro.mp4";
import about1 from "../images/about01.jpg";
import about2 from "../images/about02.jpg";
import about3 from "../images/about03.jpg";
import about4 from "../images/about04.jpg";
import about5 from "../images/about05.jpg";
import about6 from "../images/about06.jpg";
import about7 from "../images/about07.jpg";
import map from "../images/map.svg";

// TODO: create video
// import IntroOgg from "../images/intro.ogg";
import BeforeImage from "../images/before.png";
import AfterImage from "../images/after.png";
import AnchorButton from "../components/AnchorButton";

const IndexPage = () => {
  const videoRef = useRef();

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (!videoRef.current.playing) {
      videoRef.current.play();
    }

    const eventListiner = videoRef?.current.addEventListener("suspend", () => {
      console.log(
        "suspend invoked ,show play button ,iphone is in low power mode"
      );
    });

    return () => {
      videoRef?.current.removeEventListiner(eventListiner);
    };
  }, []);

  return (
    <main className="overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Maritime Virtual Tours Inc. | Moncton | 3D virtual tours | 3D virtual
          staging
        </title>
        <meta
          name="description"
          content="Let us create a stunning 3D virtual tour for your real estate listing. Sell homes faster and for top dollar."
        />
        <meta
          name="keywords"
          content="Virtual tour moncton, virtual tour fredericton, virtual tour saint john,  property tour, 3D virtual tour, virtual tour, virtual tours, video tour, my property tour, propicsta, photovision360, matterport, matterport tour, 360 video, real estate video tour, 3D virtual staging, virtual staging, house staging, 3D tour, real estate photography, 360 photography, 360 images, VR, 360 view, real estate agency, photography, 3D 360, photography service, 3D photography, real estate agent, 2D schematic plan, house plan, 3D house plan, proper measure, drone photography, maritime, maritime virtual, maritime virtual tours, new brunswick virtual tour."
        />
        <meta
          property="og:image"
          content="https://www.maritimevirtual.ca/og.jpg"
        />
      </Helmet>
      <Navbar />
      <section className="relative overflow-hidden landscape:h-screen z-20">
        <div
          id="home"
          className="py-32 landscape:h-screen flex items-center justify-center"
        >
          <div className="container px-4 lg:px-12 mx-auto relative z-10">
            <h1 className="uppercase text-white text-4xl md:text-6xl lg:text-8xl font-black text-center pb-6">
              Go Virtual
            </h1>
            <div className="w-full text-white font-black text-lg lg:text-2xl relative h-24 sm:h-20 md:h-16">
              <div className="text-center w-full font-black opacity-0 absolute left-1/2 -translate-x-1/2 w1">
                Join the new way of selling and buying homes.
              </div>
              <div className="text-center w-full font-black opacity-0 absolute left-1/2 -translate-x-1/2 w2">
                Take advantage of the most transformative tools in marketing.
              </div>
              <div className="text-center w-full font-black opacity-0 absolute left-1/2 -translate-x-1/2 w3">
                Make your property stand out.
              </div>
              <div className="text-center w-full font-black opacity-0 absolute left-1/2 -translate-x-1/2 w4">
                Utilize this advanced tool for emergency planning, design and
                renovations.
              </div>
            </div>
            <div className="flex justify-center">
              <Link
                to="contact"
                smooth={true}
                duration={500}
                className="transition ease-in-out hover:scale-110 duration-500  bg-white text-black rounded shadow inline-flex items-center justify-center py-4 px-6 mx-auto cursor-pointer"
              >
                <span className="pr-1 lg:text-lg font-bold">BOOK NOW</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 transition duration-500 group-hover:translate-x-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <Link
            to="about"
            smooth={true}
            duration={500}
            offset={-50}
            className="absolute bottom-6 z-10 rounded-full border-4 border-white text-white bg-white bg-opacity-10 items-center justify-center flex animate-bounce cursor-pointer w-12 h-12"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 14l-7 7m0 0l-7-7m7 7V3"
              />
            </svg>
          </Link>
        </div>
        <div className="min-h-full min-w-full absolute z-0 inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-zinc-900 to-zinc-600 z-10 opacity-40" />
          <video
            ref={videoRef}
            loop
            muted
            autoPlay
            playsInline
            className=" object-cover h-full w-full"
            // poster="https://assets.codepen.io/6093409/river.jpg"
          >
            <source src={IntroMp4} type="video/mp4" />
            {/* <source src={IntroOgg} type="video/ogg" /> */}
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      <section id="about" className="pt-20 md:pt-24 relative z-20">
        <div className="container px-4 lg:px-12 mx-auto flex flex-wrap lg:flex-nowrap relative justify-between">
          <div className="md:pt-12 lg:pb-12">
            <h2 className="uppercase text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-extrabold pb-4 leading-6 whitespace-nowrap">
              Top Quality.
              <br /> Client satisfaction.
              <br />
              Genuine customer service.
            </h2>
            <p className="max-w-lg xl:max-w-xl text-gray-200 leading-relaxed xl:text-lg pb-6 lg:pb-0">
              Maritime Virtual Tours believe you and your business deserve the
              best! That is why we use industry leading hardware and software.
              We guarantee high quality products that are bound to impress!
              Don't miss out! Get in touch with us today to get your stunning{" "}
              <Link
                to="matterporr_3d_virtual_tour"
                offset={-100}
                smooth={true}
                duration={500}
                className="underline decoration-red-500 underline-offset-4 hover:underline-offset-2 cursor-pointer"
              >
                Matterport 3D virtual tour
              </Link>
              ,{" "}
              <Link
                to="virtual_staging"
                offset={-100}
                smooth={true}
                duration={500}
                className="underline decoration-purple-500 underline-offset-4 hover:underline-offset-2 cursor-pointer"
              >
                virtual staging
              </Link>{" "}
              &{" "}
              <Link
                to="drone_aerial_photography_and_videography"
                offset={-100}
                smooth={true}
                duration={500}
                className="transition duration-500 underline decoration-orange-500 underline-offset-4 hover:underline-offset-2 cursor-pointer"
              >
                drone aerial photography and videography
              </Link>
              !
            </p>
          </div>
          <div className="w-full flex-1 relative lg:py-12">
            <div className="lg:absolute lg:left-20 xl:left-24 2xl:left-32 h-64 lg:h-72 xl:h-80 2xl:h-96 lg:w-screen-60 xl:w-screen-60 2xl:w-screen-70 bg-white rounded-2xl overflow-hidden border-4 border-white">
              <Swiper
                className="h-full w-full"
                modules={[EffectFade, Autoplay, Pagination]}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: true,
                }}
                pagination={{ clickable: true }}
                watchOverflow={true}
                loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 4,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 4,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 4,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 4,
                  },
                  1280: {
                    slidesPerView: 2,
                    spaceBetween: 4,
                  },
                }}
              >
                <SwiperSlide>
                  <img
                    src={about1}
                    alt="Example photo of residential house"
                    className="object-cover h-full w-full rounded-2xl"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={about2}
                    alt="4K HDR living room photograph"
                    className="object-cover h-full w-full rounded-2xl"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={about3}
                    alt="Matterport 3D dool house"
                    className="object-cover h-full w-full rounded-2xl"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={about4}
                    alt="Matterport 2D floor plan"
                    className="object-cover h-full w-full rounded-2xl"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={about5}
                    alt="2D black and white schematic house plan"
                    className="object-cover h-full w-full rounded-2xl"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={about6}
                    alt="Drone aerial view of property"
                    className="object-cover h-full w-full rounded-2xl"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={about7}
                    alt="Matterport 3D commercial building"
                    className="object-cover h-full w-full rounded-2xl"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="container px-4 lg:px-12 mx-auto">
          <div className="py-12 md:pt-32">
            <h3 className="uppercase text-lg md:text-2xl xl:text-3xl font-extrabold pb-6">
              Did you know?
            </h3>
            <div className="hidden xl:grid grid-cols-5 gap-6">
              <div className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                <span>
                  Properties featuring 3D virtual tours sell faster and increase
                  the sale price by an average of <strong>6%</strong>.
                </span>
              </div>
              <div className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                <span>
                  Buyers are <strong>300% more</strong> engaged with a 3D
                  virtual tour than 2D imagery.
                </span>
              </div>
              <div className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                <span>
                  Virtual tours keep potential customers on your website{" "}
                  <strong>10 times</strong> longer.
                </span>
              </div>
              <div className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                <span>
                  <strong>67% of people</strong> want to see more businesses
                  showcasing their 3D virtual tour.
                </span>
              </div>
              <div className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                <span>
                  There are more than <strong>5 million</strong> visits daily on
                  world wide 3D virtual tours.
                </span>
              </div>
            </div>
            <div className="xl:hidden relative">
              <div className="">
                <Swiper
                  className="overflow-hidden"
                  modules={[Autoplay, Pagination]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                  pagination={{ clickable: true }}
                  watchOverflow={true}
                  loop={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 12,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 24,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1280: {
                      slidesPerView: 4,
                      spaceBetween: 44,
                    },
                  }}
                >
                  <SwiperSlide className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                    <span>
                      Properties featuring 3D virtual tours sell faster and
                      increase the sale price by an average of{" "}
                      <strong>6%</strong>.
                    </span>
                  </SwiperSlide>
                  <SwiperSlide className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                    <span>
                      Buyers are <strong>300% more</strong> engaged with a 3D
                      virtual tour than 2D imagery.
                    </span>
                  </SwiperSlide>
                  <SwiperSlide className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                    <span>
                      Virtual tours keep potential customers on your website{" "}
                      <strong>10 times</strong> longer.
                    </span>
                  </SwiperSlide>
                  <SwiperSlide className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                    <span>
                      <strong>67% of people</strong> want to see more businesses
                      showcasing their 3D virtual tour.
                    </span>
                  </SwiperSlide>
                  <SwiperSlide className="border-4 border-white bg-white bg-opacity-10 rounded-2xl p-4 xl:p-6 text-center h-auto flex items-center justify-center">
                    <span>
                      There are more than <strong>5 million</strong> visits
                      daily on world wide 3D virtual tours.
                    </span>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services"
        className="pt-20 md:pt-24 relative z-10 pb-16 md:pb-32"
      >
        <div className="container px-4 lg:px-12 mx-auto">
          <div className="mb-12 md:mb-16 lg:mb-32">
            <h2 className="uppercase text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-extrabold pb-12 leading-tight md:text-center">
              What we offer
            </h2>
          </div>
          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-24 mb-24 lg:mb-32 xl:mb-40"
            id="matterporr_3d_virtual_tour"
          >
            <div className="">
              <iframe
                title="Matterport Example"
                width="100%"
                src="https://my.matterport.com/show/?m=11wC2xxx9gv"
                frameBorder="0"
                allow="xr-spatial-tracking"
                className="rounded-2xl aspect-video"
              ></iframe>
            </div>
            <div>
              <div className="flex flex-wrap">
                <div className="flex flex-col items-start">
                  <h3 className="uppercase text-lg md:text-2xl xl:text-3xl font-extrabold pb-4">
                    Matterport 3D Virtual Tour
                  </h3>
                  <div className="w-full md:pb-6">
                    <p className="text-gray-300 pb-6">
                      At Maritime Virtual Tours we offer{" "}
                      <span className="uppercase font-bold">
                        3D virtual tours
                      </span>{" "}
                      for residential and commercial property or anyone looking
                      to add value to their business. We work with real estate
                      agents, insurance companies, businesses and individuals.
                    </p>
                    <p className="text-gray-300 pb-6">
                      Your <strong>Matterport 3D virtual tour</strong> includes
                      an interactive 3D/2D doll house floor plan with a precise
                      measuring tool.{" "}
                      <strong>2D black and white schematic floor plan</strong>{" "}
                      and up to <strong>30 HDR quality photographs</strong>{" "}
                      available at additional cost.
                    </p>

                    <AnchorButton />

                    <p className="text-gray-400 mt-6">
                      *Pricing and turnaround time may vary based on the size
                      and complexity of the space.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col-reverse lg:grid grid-cols-1 lg:grid-cols-2 lg:gap-24 mb-24 lg:mb-32 xl:mb-40"
            id="virtual_staging"
          >
            <div className="mt-6 lg:mt-0">
              <div className="flex flex-wrap">
                <div className="flex flex-col items-start">
                  <h3 className="uppercase text-lg md:text-2xl xl:text-3xl font-extrabold pb-4">
                    3D Virtual Staging
                  </h3>
                  <div className="w-full flex flex-col md:flex-row items-start justify-between md:pb-6">
                    <div>
                      <p className="text-gray-300 max-w-xl">
                        Maritime Virtual Tours offers the most realistic{" "}
                        <span className="uppercase font-bold">
                          3D virtual staging
                        </span>
                        . <span className="whitespace-nowrap">3D virtual</span>{" "}
                        staging is a modern tool in real estate marketing that
                        brings any empty space to life and helps buyers connect
                        with the property. Virtual staging is more affordable
                        and easier than conventional home staging. We can stage
                        the Matterport 3D tour or just still images.
                      </p>
                      <ul className="list-disc ml-4 text-gray-300 mt-4">
                        <li>Make any house look like a home. </li>
                        <li>
                          Allow the buyers to see themselves living in the
                          property.{" "}
                        </li>
                        <li>
                          Increase the probability of selling your property
                          faster and for a higher price
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <AnchorButton />
            </div>

            <div className="">
              <iframe
                title="Matterport Example"
                width="100%"
                src="https://my.matterport.com/show/?m=4bbbtRkzxkg"
                frameBorder="0"
                allow="xr-spatial-tracking"
                className="rounded-2xl aspect-video"
              ></iframe>
            </div>
            {/* <div className="flex relative w-full aspect-video overflow-hidden">
              <div className="bg-zinc-900 absolute left-2 top-2 z-10 rounded-lg px-2 py-0.5">
                Before
              </div>
              <div className="bg-zinc-900 absolute right-2 top-2 z-10 rounded-lg px-3 py-0.5">
                After
              </div>
              <ReactCompareSlider
                className="rounded-2xl overflow-hidden w-full"
                itemOne={
                  <img
                    src={BeforeImage}
                    alt="Virtual Staging - Before"
                    className="w-full h-full object-cover"
                  />
                }
                itemTwo={
                  <img
                    src={AfterImage}
                    alt="Virtual Staging - After"
                    className="w-full h-full object-cover"
                  />
                }
              />
            </div> */}
          </div>

          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-24"
            id="drone_aerial_photography_and_videography"
          >
            <div className="">
              <iframe
                width="100%"
                src="https://www.youtube.com/embed/mv_gZFFqjRQ?si=kZTD8E88sZOEqMKX"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                className="rounded-2xl aspect-video"
              ></iframe>
            </div>
            <div>
              <div className="flex flex-wrap">
                <div className="flex flex-col items-start">
                  <h3 className="uppercase text-lg md:text-2xl xl:text-3xl font-extrabold pb-4">
                    DRONE AERIAL PHOTOGRAPHY & VIDEOGRAPHY
                  </h3>
                  <div className="w-full md:pb-6">
                    <p className="text-gray-300 pb-6">
                      Maritime Virtual Tours offers drone aerial photography and
                      videography. We can help you capture the entire property
                      from the sky. Survey your land or use the drone aerial
                      photography and videography for marketing purposes.
                      <br />
                      <br />
                      Please check out our{" "}
                      <a
                        href="https://www.youtube.com/@maritimevirtualtoursinc.5057"
                        target="_blank"
                        className="underline text-white hover:underline-offset-2 decoration-pink transition duration-500 underline-offset-4"
                      >
                        YouTube channel
                      </a>{" "}
                      for more videos.
                    </p>
                    <AnchorButton />
                    <p className="text-gray-400 mt-6">
                      *Please call or email for a quote as prices vary for each
                      project.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="relative">
        <div className="container px-4 lg:px-12 mx-auto relative z-10 min-h-screen pt-24 flex flex-col justify-between">
          <h2 className="uppercase text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-extrabold pb-8 md:pb-12 leading-tight text-center">
            Contact us
          </h2>
          <div className="flex justify-center md:justify-end pb-24">
            <div className="bg-white text-black rounded-2xl mb-6 px-12 py-10 mt-6 font-black">
              <div className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mt-2 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <a
                  href="tel:+15068991481"
                  className="text-lg hover:text-zinc-600 p-2"
                >
                  (506) 899-1481
                </a>
              </div>
              <div className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mt-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <a
                  href="mailto:info@maritimevirtual.ca"
                  className="text-lg hover:text-zinc-600 p-2"
                >
                  info@maritimevirtual.ca
                </a>
              </div>
              <div className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mt-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                  />
                </svg>
                <address className="font-normal not-italic p-2">
                  Maritime Virtual Tours Inc.
                  <br />
                  7012 Route 112
                  <br />
                  Hunters Home
                  <br />
                  New Brunswick
                  <br />
                  E4C 4H8
                </address>
              </div>
            </div>
          </div>

          <div className="max-w-lg mx-auto mb-10">
            <p className="text-gray-400 mt-6 text-center">
              We serve Atlantic Canada - New Brunswick, Nova Scotia, Prince
              Edward Island & Newfoundland and Labrador. Travel fee may apply.
            </p>
          </div>
        </div>

        <img
          src={map}
          alt="Maritime Virtual Tours Map Locations"
          className="absolute bottom-28 z-0 w-full md:w-1/2"
        />
      </section>

      <footer className="bg-white w-full py-6 ">
        <div className="container px-4 lg:px-12 mx-auto flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 justify-between items-center">
          <div className="flex-1">
            <StaticImage
              src="../images/logo-black.svg"
              alt="Maritime Virtual Tours Logo"
              width={165}
              height={44}
            />
          </div>
          <div className="flex-2">
            <p className="text-gray-500 ">
              © {new Date().getFullYear()} Maritime Virtual Tours Inc.
            </p>
          </div>
          <div className="flex-1 flex justify-end space-x-2">
            {/* <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235V7.95H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.028 24 18.062 24 12.073Z"
                fill="#333"
              />
            </svg> */}
            <a
              href="https://www.instagram.com/maritimevirtualtours/"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63a5.876 5.876 0 0 0-2.126 1.384A5.855 5.855 0 0 0 .63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 0 0 1.384 2.126A5.868 5.868 0 0 0 4.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 0 0 2.126-1.384 5.86 5.86 0 0 0 1.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 0 0-1.384-2.126A5.847 5.847 0 0 0 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0Zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85 0 3.204-.015 3.585-.074 4.85-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 0 1-.899 1.382 3.744 3.744 0 0 1-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 0 1-1.379-.899 3.644 3.644 0 0 1-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03Zm0 3.678a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 1 0 0-12.324ZM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm7.846-10.405a1.441 1.441 0 0 1-2.88 0 1.44 1.44 0 0 1 2.88 0Z"
                  fill="#333"
                />
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default IndexPage;
