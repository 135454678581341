import React, { useCallback, useState } from "react";
import Sticky from "react-stickynode";
import { Link } from "react-scroll";
import logo from "../images/logo-white.svg";

const Navbar = () => {
  const [isSticky, setSticky] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const handleStateChange = useCallback((status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setSticky(true);
      return;
    }
    if (status.status === Sticky.STATUS_ORIGINAL) {
      setSticky(false);
      return;
    }
    setSticky(false);
  }, []);

  const closeMenu = useCallback(() => setOpen(false));
  return (
    <Sticky onStateChange={handleStateChange} className="z-30 relative">
      <nav
        className={`absolute top-0 left-0 w-full z-20 px-2 transition-all duration-500 ${
          isSticky ? "bg-opacity-80 bg-black shadow py-3" : "py-6"
        }`}
      >
        <div className="container px-4 lg:px-12 flex flex-wrap justify-between items-center mx-auto">
          <Link
            to="home"
            title="Home"
            smooth={true}
            duration={500}
            className="cursor-pointer"
          >
            <img
              src={logo}
              alt="Maritime Virtual Tours Logo"
              width={164}
              height={44}
            />
          </Link>
          <div>
            <button
              onClick={() => setOpen(true)}
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg md:hidden focus:outline-none"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <div
              className={`${
                isOpen ? "block" : "hidden"
              } absolute top-0 right-0 bg-black px-6 py-6 shadow-lg rounded-bl-2xl`}
            >
              <div className="flex justify-end">
                <button
                  onClick={closeMenu}
                  type="button"
                  className="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg md:hidden focus:outline-none"
                >
                  <span className="sr-only">Close main menu</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <Link
                  activeClass="navlink-active"
                  to="home"
                  title="Home"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                  className="rounded block py-2 px-4 md:py-1 md:px-2 pl-3 text-white cursor-pointer"
                >
                  Home
                </Link>
              </div>
              <div>
                <Link
                  activeClass="navlink-active"
                  to="about"
                  title="About"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                  className="rounded block py-2 px-4 md:py-1 md:px-2 pl-3 text-white cursor-pointer"
                >
                  About
                </Link>
              </div>
              <div>
                <Link
                  activeClass="navlink-active"
                  to="services"
                  title="Services"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                  className="rounded block py-2 px-4 md:py-1 md:px-2 pl-3 text-white cursor-pointer"
                >
                  Services
                </Link>
              </div>
              <div>
                <Link
                  activeClass="navlink-active"
                  to="contact"
                  title="Contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={closeMenu}
                  className="rounded block py-2 px-4 md:py-1 md:px-2 pl-3 text-white hover:bg-white hover:text-black cursor-pointer"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden w-full md:block md:w-auto">
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                <Link
                  activeClass={isSticky ? "navlink-active" : ""}
                  to="home"
                  title="Home"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="rounded block py-2 px-4 md:py-1 md:px-2 pl-3 text-white hover:bg-white hover:text-black cursor-pointer"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  activeClass="navlink-active"
                  to="about"
                  title="About"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="rounded block py-2 px-4 md:py-1 md:px-2 pl-3 text-white hover:bg-white hover:text-black cursor-pointer"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  activeClass="navlink-active"
                  to="services"
                  title="Services"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="rounded block py-2 px-4 md:py-1 md:px-2 pl-3 text-white hover:bg-white hover:text-black cursor-pointer"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  activeClass="navlink-active"
                  to="contact"
                  title="Contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="rounded block py-2 px-4 md:py-1 md:px-2 pl-3 text-white hover:bg-white hover:text-black cursor-pointer"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Sticky>
  );
};

export default Navbar;
