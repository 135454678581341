import React from "react";
import { Link } from "react-scroll";

const AnchorButton = ({ text = "Get a quote", to = "contact" }) => {
  return (
    <Link
      to={to}
      smooth={true}
      duration={500}
      className="group transition ease-in-out hover:scale-110 duration-500 bg-white text-black rounded shadow inline-flex items-center justify-center py-3 px-5 mx-auto mt-6 cursor-pointer"
    >
      <span className="pr-1 font-bold uppercase">{text}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 transition duration-500 group-hover:translate-x-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </Link>
  );
};

export default AnchorButton;
